@font-face {
  font-family: Burbank;
  src: url('../fonts/Burbank Big Regular Medium.otf');
  font-weight: 400;
}

@font-face {
  font-family: Burbank;
  src: url('../fonts/Burbank Big Regular Black.otf');
  font-weight: 600;
}

*, *:after, *:before {
  box-sizing: border-box;
  text-transform: uppercase !important;
  color: var(--text-100);
}

button {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroller {
  --scrollbar-color-thumb: var(--primary-100);
  --scrollbar-color-track: var(--bg-200);
  --scrollbar-width: thin;
  --scrollbar-width-legacy: 10px;
}

/* Modern browsers with `scrollbar-*` support */
@supports (scrollbar-width: auto) {
  .scroller {
    scrollbar-color: var(--scrollbar-color-thumb) var(--scrollbar-color-track);
    scrollbar-width: var(--scrollbar-width);
  }
}

/* Legacy browsers with `::-webkit-scrollbar-*` support */
@supports selector(::-webkit-scrollbar) {
  .scroller::-webkit-scrollbar-thumb {
    background: var(--scrollbar-color-thumb);
  }
  .scroller::-webkit-scrollbar-track {
    background: var(--scrollbar-color-track);
  }
  .scroller::-webkit-scrollbar {
    max-width: var(--scrollbar-width-legacy);
    max-height: var(--scrollbar-width-legacy);
  }
}


html {
  /* theme colors */
  --primary-green-100: #2b8a3e;
  --primary-green-200: #2b8a3ecc;
  --primary-blue-100: #1864ab;
  --primary-blue-200: #1c7ed6;
  --primary-purple-100: #5f3dc4;
  --primary-purple-200: #7048e8;
  --primary-orange-100: #e8590c;
  --primary-orange-200: #f76707;

  /* primary default */
  --primary-100: var(--primary-green-100);
  --primary-200: var(--primary-green-200);

  /* text */
  --text-100: #e9ecef;

  /* background (dark) */
  --bg-100: #141414;
  --bg-200: #25262b;


  /* variables */
  --size: 16px;
  --header-height: 50px;
  --max-app-width: 850px;

  font-family: 'Burbank', 'Ubuntu', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  letter-spacing: 0.08rem;
  font-size: var(--size);
}

html.green {
  --primary-100: var(--primary-green-100);
  --primary-200: var(--primary-green-200);
}

html.blue {
  --primary-100: var(--primary-blue-100);
  --primary-200: var(--primary-blue-200);
}

html.purple {
  --primary-100: var(--primary-purple-100);
  --primary-200: var(--primary-purple-200);
}

html.orange {
  --primary-100: var(--primary-orange-100);
  --primary-200: var(--primary-orange-200);
}

/* iOS full screen height */
@supports (-webkit-touch-callout: none) {
  .full-height {
    height: -webkit-fill-available !important;
  }
}

body {
  height: 100vh;
  max-width: var(--max-app-width);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background: var(--bg-100);
}

/* SHARED */

.hidden {
  display: none !important;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-heading {
  margin: 0;
}

.page-joke-count {
  min-width: 30px;
  text-align: center;
  font-weight: 600;
  padding: 0.375rem 0.5rem 0.25rem;
  margin: 0.1rem 0 0.5rem;
  border-radius: 0.5rem;
  background: var(--primary-100);
}

/* SEARCH */

#search-wrap {
  margin: 1rem 0;
  background: var(--bg-200);
  border-radius: 1rem;
  overflow: hidden;
}

#search-wrap svg {
  width: 1.5rem;
  height: 100%;
  fill: none;
  stroke: var(--primary-100);
}

#search-form {
  display: flex;
  padding: 0.5rem 0.5rem;
  border: 2px solid var(--bg-200);
  border-radius: 1rem;
}

#search-form:hover,
#search-form:focus,
#search-form:active {
  border: 2px solid var(--primary-100);
}

#search-form input {
  width: 100%;
  font-size: 1.25rem;
  line-height: 1.25rem;
  border: none;
  padding: 0.5rem 0.5rem 0.25rem;
  background: var(--bg-200);
}

#search-form input:focus-visible {
  outline: none;
}
